import logo from './logo.svg';
import './App.css';
import './components/GameComponent'
import GameComponent from './components/GameComponent';

function App() {
  console.log("hello world")
  return (<div>
    <d1>hello saye</d1>
{/* <GameComponent></GameComponent> */}
</div>
  );
}

export default App;
